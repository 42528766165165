import { Controller } from "stimulus";
import WindowAnimation from "../lib/WindowAnimation";

export default class extends Controller {
  hasFigureTarget: boolean;
  figureTarget: HTMLElement;
  handle: number;

  static get targets() {
    return ["figure"];
  }

  connect() {
    let figureHeight = null,
      contentHeight = null,
      windowHeight = null,
      scrollLength = null;

    if (!this.hasFigureTarget) {
      return;
    }

    this.element.classList.add("figure-overlay");

    this.handle = WindowAnimation.register({
      resize: (_m, height) => {
        const rect = this.element.getBoundingClientRect();
        const figureRect = this.figureTarget.getBoundingClientRect();

        figureHeight = figureRect.height;
        contentHeight = rect.height;
        windowHeight = height;
        scrollLength =
          document.body.clientHeight -
          contentHeight +
          (figureHeight - windowHeight);
      },
      scroll: (offset) => {
        if (contentHeight > figureHeight + 50) {
          const position = offset / (document.body.clientHeight - windowHeight);
          this.figureTarget.classList.add("fixed");
          this.figureTarget.style.transform =
            "translate3d(0px, " + (0 - position * scrollLength) + "px, 0px)";
        } else {
          this.figureTarget.classList.remove("fixed");
          this.figureTarget.style.transform = "translate3d(0px, 0px, 0px)";
        }
      }
    });
  }

  disconnect() {
    WindowAnimation.clear(this.handle);
  }
}
